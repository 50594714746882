import gql from 'graphql-tag'


const listResponse = `
  id type paperId
  createdAt createdUser {id name}
  options {
    probabilityPoint probabilityChancePoint
    totalPoint totalLevel
    revisionPlan remark
  }
`

export const LIST_LOG = (templateType) => gql`query LIST_LOG ($paperType: String!, $paperId: Int!) {
  logs: list${templateType}Log (paperType: $paperType, paperId: $paperId) {${listResponse}}
}`

export const CREATE_LOG = (templateType) => gql`mutation CREATE_LOG ($paperType: String!, $paperId: Int!, $createdDate: Date!, $input: ${templateType}LogInput!) {
  log: create${templateType}Log (paperType: $paperType, paperId: $paperId, createdDate: $createdDate, input: $input) {id}
}`

export const DESTROY_LOG = (templateType) => gql`mutation DESTROY_LOG ($paperType: String!, $logId: Int!) {
  log: destroy${templateType}Log (paperType: $paperType, logId: $logId) {id}
}`
